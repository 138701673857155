import { Controller, useFormContext } from "react-hook-form";
import { ReactComponent as Contract } from "@/common/assets/svg/triggers/contact_created.svg";
import { ReactComponent as Meeting } from "@/common/assets/svg/triggers/meeting.svg";
import { ReactComponent as Prospect } from "@/common/assets/svg/triggers/prospect.svg";
import Box from "@mui/material/Box";
import { useAppDispatch, useAppSelector } from "@/redux/store";
import { useEffect } from "react";
import { getDealsList } from "@/redux/reducers/deals/slices/listDeals";
import { MeetingStateChangesType } from "./MeetingStateChangesType";
import { ContactCreatedForm } from "./ContactCreatedForm";
import { ListByEventTypeEventEnum } from "@/services/generated";
import { ProspectEngagesResponses } from "./ProspectEngagesResponses";
import TriggerEventTypeOption from "@/pages/triggers/edit/steps/event/TriggerEventTypeOption";

const EVENT_TYPE_OPTIONS = [
  {
    title: "Contact Created",
    icon: <Contract />,
    value: ListByEventTypeEventEnum.NewContact,
    form: <ContactCreatedForm />,
  },
  {
    title: "Meeting State Change",
    icon: <Meeting />,
    value: ListByEventTypeEventEnum.MeetingStateChange,
    form: <MeetingStateChangesType />,
  },
  {
    title: "Prospect Engagement / Responses",
    icon: <Prospect />,
    value: ListByEventTypeEventEnum.ProspectEngagement,
    form: <ProspectEngagesResponses />,
  },
];

export default function LeadTriggers() {
  const methods = useFormContext();
  const { control } = methods;
  const deals = useAppSelector(({ deals }) => deals.listDeals.deals);
  const dispatch = useAppDispatch();
  const eventType = methods.watch("event.eventType");

  useEffect(() => {
    if (deals === undefined) {
      dispatch(getDealsList());
    }
  }, [dispatch, deals]);

  return (
    <div
      style={{
        width: "100%",
        maxWidth: "800px",
        display: "flex",
        flexDirection: "column",
        gap: "16px",
        margin: "0 auto",
      }}
    >
      {EVENT_TYPE_OPTIONS.map(({ title, icon, value, form }) => (
        <div key={title}>
          <Controller
            name="event.eventType"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <TriggerEventTypeOption title={title} icon={icon} value={value} field={field} />
            )}
          />
          {eventType === value && (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                mt: 2,
                mx: 5,
              }}
            >
              {form}
            </Box>
          )}
        </div>
      ))}
    </div>
  );
}
