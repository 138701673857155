import Box from "@mui/material/Box";
import { Typography, useTheme } from "@mui/material";
import { ControllerRenderProps, FieldValues } from "react-hook-form";

type TriggerEventTypeOptionProps = {
  title: string;
  icon: React.ReactNode;
  value: string;
  field: ControllerRenderProps<FieldValues, "event.eventType">;
};

export default function TriggerEventTypeOption({ title, icon, value, field }: TriggerEventTypeOptionProps) {
  const theme = useTheme();

  return (
    <label htmlFor={value} style={{ cursor: "pointer", width: "100%" }}>
      <div
        style={{
          borderRadius: "8px",
          padding: "10px",
          border: `${field.value === value ? `2px solid #1C73C1` : "1px solid #E0E0E0"}`,
        }}
      >
        <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
          <Box
            sx={{
              background: theme.palette.mint.p,
              padding: "4px",
              borderRadius: "4px",
              display: "flex",
              alignItems: "center",
            }}
          >
            {icon}
          </Box>

          <div style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
            <div>
              <Typography variant="body2" sx={{ fontWeight: "700" }}>
                {title}
              </Typography>
            </div>

            <input
              type="radio"
              id={value}
              {...field}
              value={value}
              checked={field.value === value}
              onChange={() => {
                field.onChange(value);
              }}
            />
          </div>
        </div>
      </div>
    </label>
  );
}
