import { DealFlowOptions } from "@/pages/triggers/edit/UpdateTrigger";
import { ListByEventTypeEventEnum } from "@/services/generated";

export enum TriggersType {
  LEAD = "LEAD",
  DEAL = "DEAL",
}

export type TriggerOption = {
  checked?: boolean;
  image: JSX.Element;
  title: string;
  description: string;
  type: TriggersType;
};

export type TriggerForm = {
  name: string;
  type: TriggersType;
  event: {
    eventType: ListByEventTypeEventEnum | "";
    eventTriggerType: TriggersType | "";
    dealFlowId: string;
    dealFlowIdType: DealFlowOptions;
    dealFlowStage: string;
  };
  action: {
    actionType: string | "";
    receiver: string[];
    message: string;
    pipelineId?: string;
    pipelineStageId?: string;
  };
  deleted: boolean;
};
