import { Typography } from "@mui/material";
import { Controller, ControllerRenderProps, FieldValues, useFormContext } from "react-hook-form";
import { KnContact2LeadSourceEnum } from "@/services/generated";

const LEAD_SOURCE_OPTIONS = [
  {
    label: "All",
    value: null,
  },
  {
    label: "UI",
    value: KnContact2LeadSourceEnum.Ui,
  },
  {
    label: "Webhook",
    value: KnContact2LeadSourceEnum.InboundWebhook,
  },
  {
    label: "Prospect Finder",
    value: KnContact2LeadSourceEnum.ProspectFinder,
  },
  {
    label: "Data Import",
    value: KnContact2LeadSourceEnum.DataImportCsv,
  },
];

export const ContactCreatedForm = () => {
  const methods = useFormContext();
  const { control } = methods;

  const renderOption = (
    field: ControllerRenderProps<FieldValues, "event.leadSource">,
    option: { label: string; value: string | null },
  ) => {
    return (
      <label
        key={option.value}
        style={{
          cursor: "pointer",
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          borderRadius: "8px",
          padding: "10px",
          border: "1px solid #E0E0E0",
        }}
      >
        <Typography variant="body2">{option.label}</Typography>
        <input type="radio" checked={field.value === option.value} onChange={() => field.onChange(option.value)} />
      </label>
    );
  };

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <Typography variant="body2" sx={{ fontWeight: 500 }}>
        Lead Source
      </Typography>
      <Controller
        name="event.leadSource"
        control={control}
        render={({ field, fieldState: { error } }) => (
          <div style={{ display: "flex", flexDirection: "column", gap: "16px", paddingTop: "8px" }}>
            {LEAD_SOURCE_OPTIONS.map((option) => renderOption(field, option))}
          </div>
        )}
      />
    </div>
  );
};
