import { Controller, useFormContext } from "react-hook-form";
import FormControl from "@mui/material/FormControl";
import Dropdown from "@/common/components/dropdown";
import { useAppDispatch, useAppSelector } from "@/redux/store";
import { useEffect, useMemo } from "react";
import { getPipelineDetails, getPipelines } from "@/redux/reducers/dealFlow/slices/listPipelines";
import FormLabel from "@/common/components/form-label/FormLabel";

export default function DealFlowStateChangeForm() {
  const dispatch = useAppDispatch();
  const methods = useFormContext();
  const { control } = methods;
  const pipelines = useAppSelector(({ dealFlow }) => dealFlow.listPipelines.pipelines) || [];
  const selectedPipelineId = methods.watch("event.dealFlowId");
  console.log(selectedPipelineId);

  useEffect(() => {
    dispatch(getPipelines());
  }, [dispatch]);

  const pipelineOptions = useMemo(() => {
    return pipelines.map((p) => {
      return {
        label: p.name,
        value: p.id,
      };
    });
  }, [pipelines]);

  useEffect(() => {
    // Adds PipelineStage data to Pipeline.
    dispatch(getPipelineDetails(selectedPipelineId));
  }, [dispatch, selectedPipelineId]);

  const pipelineStageOptions = useMemo(() => {
    const pipelineStages = pipelines.find((p) => p.id === selectedPipelineId)?.stages || [];
    return pipelineStages.map((s) => {
      return {
        label: s.name,
        value: s.id,
      };
    });
  }, [pipelines, selectedPipelineId]);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "16px",
      }}
    >
      <Controller
        name="event.dealFlowId"
        control={control}
        render={({ field }) => (
          <FormControl>
            <FormLabel label="Pipeline" aria-required />
            <Dropdown
              options={pipelineOptions}
              value={field.value}
              onChange={(option) => field.onChange(option.value)}
            />
          </FormControl>
        )}
      />
      <Controller
        name="event.dealFlowStage"
        control={control}
        render={({ field, fieldState: { error } }) => (
          <FormControl>
            <FormLabel label="Pipeline Stage" aria-required />
            <Dropdown
              options={pipelineStageOptions}
              value={field.value}
              onChange={(option) => field.onChange(option.value)}
            />
          </FormControl>
        )}
      />
    </div>
  );
}
