import Button from "@/common/components/button";
import { FullLoadingOverlay } from "@/common/components/loading-overlay/FullLoadingOverlay";
import Stepper from "@/common/components/stepper/Stepper";
import routes from "@/common/constants/routes";
import toast from "@/lib/toast";
import AgentStep from "@/pages/ai-voice/ai-agent-edit/steps/agent-step";
import AgentSummaryStep from "@/pages/ai-voice/ai-agent-edit/steps/agent-summary-step";
import CallFlowStep from "@/pages/ai-voice/ai-agent-edit/steps/call-flow-step";
import { lookupAgents } from "@/redux/reducers/ai-voice/listAgents";
import { createAgent, getAgent, updateAgent } from "@/redux/reducers/ai-voice/upsertAgent";
import { useAppDispatch, useAppSelector } from "@/redux/store";
import {
  KnAiAgentCallDirectionEnum,
  KnAiAgentCallFlowEnum,
  KnAiAgentCallToActionEnum,
  KnAiAgentLanguageEnum,
  KnAiAgentRequest,
  KnAiAgentRequestBookingModeEnum,
  KnAiAgentRequestCallToActionEnum,
  KnAiAgentRequestTransferEnum,
  KnAiAgentBookingModeEnum,
} from "@/services/generated";
import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Divider, Stack, Typography } from "@mui/material";
import { ArrowLeftOutline, ArrowRightOutline } from "heroicons-react";
import { useCallback, useEffect, useState } from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";
import AgentTypeStep from "./steps/agent-type-step";

const bookingModeValues: KnAiAgentRequestBookingModeEnum[] = [
  KnAiAgentRequestBookingModeEnum.RoundRobin,
  KnAiAgentRequestBookingModeEnum.OneToOne,
];

const CALL_FLOW_REQUIRE_BOOKING_MODE: KnAiAgentCallFlowEnum[] = [
  KnAiAgentCallFlowEnum.QualifyAndBookAMeeting,
  KnAiAgentCallFlowEnum.BookAMeeting,
];

const CALL_FLOW_REQUIRE_BOOKING_WITH: KnAiAgentCallFlowEnum[] = [
  KnAiAgentCallFlowEnum.QualifyAndBookAMeeting,
  KnAiAgentCallFlowEnum.BookAMeeting,
];

const CALL_FLOW_REQUIRE_KNOWLEDGE_BASE: KnAiAgentCallFlowEnum[] = [KnAiAgentCallFlowEnum.AnswerFaqs];

const CALL_FLOW_REQUIRE_CALL_TO_ACTION: KnAiAgentCallFlowEnum[] = [
  KnAiAgentCallFlowEnum.AnswerFaqs,
  KnAiAgentCallFlowEnum.Prompt,
];

const CALL_FLOW_REQUIRE_TRANSFER: KnAiAgentCallFlowEnum[] = [KnAiAgentCallFlowEnum.QualifyAndLiveTransfer];

const validateStepRequired = <T extends Yup.Schema>(schema: T, step: number) =>
  schema.when("$step", {
    is: (s: number) => s === step,
    then: (schema) => schema.required(),
  });

export const showAndReqBookingMode = (callFlow: KnAiAgentCallFlowEnum) => {
  return CALL_FLOW_REQUIRE_BOOKING_MODE.includes(callFlow);
};

export const showAndReqBookingWith = (callFlow: KnAiAgentCallFlowEnum, bookingMode?: KnAiAgentBookingModeEnum) => {
  return CALL_FLOW_REQUIRE_BOOKING_WITH.includes(callFlow) && bookingMode === KnAiAgentBookingModeEnum.OneToOne;
};

export const reqKnowledgeBase = (callFlow: KnAiAgentCallFlowEnum) => {
  return CALL_FLOW_REQUIRE_KNOWLEDGE_BASE.includes(callFlow);
};

export const showAndReqCallToAction = (callFlow: KnAiAgentCallFlowEnum) => {
  return CALL_FLOW_REQUIRE_CALL_TO_ACTION.includes(callFlow);
};

export const showAndReqTransfer = (callFlow: KnAiAgentCallFlowEnum, callToAction?: KnAiAgentCallToActionEnum) => {
  return CALL_FLOW_REQUIRE_TRANSFER.includes(callFlow) || callToAction === KnAiAgentCallToActionEnum.LiveTransfer;
};

const schema: Yup.ObjectSchema<KnAiAgentRequest> = Yup.object({
  // Agent Type
  call_direction: validateStepRequired(
    Yup.string<KnAiAgentCallDirectionEnum>().label("Call Direction").oneOf(Object.values(KnAiAgentCallDirectionEnum)),
    0,
  ),

  // Agent
  language: validateStepRequired(
    Yup.string<KnAiAgentLanguageEnum>().oneOf(Object.values(KnAiAgentLanguageEnum)).label("Language"),
    1,
  ),
  agent_phone_number: validateStepRequired(Yup.string().label("Agent Phone Number"), 1),
  voice_id: validateStepRequired(Yup.string().label("Voice"), 1),
  creativity: validateStepRequired(Yup.number().label("Creativity"), 1),
  interrupt: validateStepRequired(Yup.number().label("Interrupt"), 1),

  // Call Flow
  call_flow: validateStepRequired(
    Yup.string<KnAiAgentCallFlowEnum>().label("Call Flow").oneOf(Object.values(KnAiAgentCallFlowEnum)),
    2,
  ),
  call_flow_prompt: Yup.string()
    .label("Prompt")
    .when("call_flow", {
      is: KnAiAgentCallFlowEnum.Prompt,
      then: (schema) => validateStepRequired(schema, 2),
    }),
  hook_statement: Yup.string().label("Hook Statement"),
  booking_mode: Yup.string<KnAiAgentRequestBookingModeEnum>()
    .oneOf(bookingModeValues)
    .label("Booking Mode")
    .when("call_flow", {
      is: showAndReqBookingMode,
      then: (schema) => validateStepRequired(schema, 2),
    }),
  booking_with: Yup.array()
    .label("Booking With")
    .when(["call_flow", "booking_mode"], {
      is: showAndReqBookingWith,
      then: (schema) => validateStepRequired(schema, 2),
    }),
  knowledge_base_url: Yup.string()
    .label("Knowledge Base URL")
    .when("call_flow", {
      is: reqKnowledgeBase,
      then: (schema) => validateStepRequired(schema, 2),
    }),
  call_to_action: Yup.mixed<KnAiAgentRequestCallToActionEnum>()
    .oneOf(Object.values(KnAiAgentRequestCallToActionEnum))
    .label("Call to Action")
    .when("call_flow", {
      is: showAndReqCallToAction,
      then: (schema) => validateStepRequired(schema, 2),
    }),
  transfer: Yup.mixed<KnAiAgentRequestTransferEnum>()
    .label("Transfer")
    .when(["call_flow", "call_to_action"], {
      is: showAndReqTransfer,
      then: (schema) => validateStepRequired(schema, 2),
    }),
  catch_webhook: Yup.string().label("3rd Party Webhook"),
  double_call: validateStepRequired(Yup.boolean().label("Double Call"), 2),
}).required();

export type AiAgentForm = Yup.InferType<typeof schema>;

const AI_AGENT_DEFAULT: AiAgentForm = {
  call_direction: KnAiAgentCallDirectionEnum.Outbound,
  // Agent
  language: "ENGLISH",
  hook_statement: "",
  catch_webhook: "",
  interrupt: 3,
  creativity: 3,

  // Call Flow
  double_call: false,
};

export default function AiAgentEdit() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const params = useParams();
  const loading = useAppSelector((selector) => selector.aiVoice.upsertAgent.loading);
  const faqId = useAppSelector((selector) => selector.aiVoice.upsertAgent.faqId);
  const agents = useAppSelector((selector) => selector.aiVoice.listAgents.data);

  const [activeStep, setActiveStep] = useState<number>(0);

  const form = useForm<AiAgentForm>({
    resolver: yupResolver(schema),
    context: { step: activeStep },
    defaultValues: AI_AGENT_DEFAULT,
  });
  const { reset, handleSubmit } = form;

  useEffect(() => {
    if (!params?.id) {
      return;
    }
    dispatch(getAgent(params.id))
      .unwrap()
      .then((agent) => {
        reset({
          call_direction: agent.call_direction,
          language: agent.language,
          agent_phone_number: agent.phone_number || "",
          voice_id: agent.voice_id || "",
          interrupt: agent.interrupt,
          creativity: agent.creativity,
          call_flow: agent.call_flow,
          call_flow_prompt: agent.call_flow_prompt || "",
          hook_statement: agent.hook_statement || "",
          booking_mode: agent.booking_mode || KnAiAgentBookingModeEnum.OneToOne,
          booking_with: agent.booking_with,
          call_to_action: agent.call_to_action || undefined,
          transfer: agent.transfer || undefined,
          catch_webhook: agent.catch_webhook || "",
          double_call: agent.double_call,
        });
      });
  }, [agents, dispatch, params, reset]);

  // Copy unique id on Knowledge Base Load

  const goToPreviousStep = () => {
    if (activeStep === 0) return;
    setActiveStep((step) => step - 1);
  };

  const onSubmit: SubmitHandler<AiAgentForm> = (data) => {
    if (activeStep === 3) {
      if (params.id) {
        dispatch(updateAgent({ id: params.id, data }))
          .unwrap()
          .then(() => {
            dispatch(lookupAgents({}));
            navigate(routes.aiVoice.agents.path);
            toast.success("Agent updated successfully!");
          })
          .catch(() => {
            toast.error("Something went wrong!");
          });
      } else {
        dispatch(createAgent(data))
          .unwrap()
          .then(() => {
            dispatch(lookupAgents({}));
            navigate(routes.aiVoice.agents.path);
            toast.success("Agent created successfully!");
          })
          .catch(() => {
            toast.error("Something went wrong!");
          });
      }
    } else {
      setActiveStep((step) => step + 1);
    }
  };

  const handleStepChange = useCallback((step: number) => step <= activeStep && setActiveStep(step), [activeStep]);

  const onCancel = () => {
    navigate(routes.aiVoice.agents.path);
  };

  return (
    <>
      <FullLoadingOverlay loading={loading} overlay={true} />
      <Stack direction="row" justifyContent="space-between" alignItems="center" mb={3}>
        <Typography fontSize="24px" fontWeight="bold">
          Create Agent
        </Typography>

        <Button variant="secondary" onClick={onCancel}>
          Cancel
        </Button>
      </Stack>
      <Stack
        direction="column"
        style={{
          background: "#fff",
          padding: "20px",
        }}
      >
        <Box>
          <Stepper
            onStepChange={handleStepChange}
            steps={[
              { idx: 0, label: "Agent Type" },
              { idx: 1, label: "Agent" },
              { idx: 2, label: "Call Flow" },
              { idx: 3, label: "Overview" },
            ]}
            activeStep={activeStep}
          />
        </Box>

        <FormProvider {...form}>
          <Stack
            direction="column"
            style={{
              background: "#fff",
              padding: "42px 24px",
              maxWidth: "544px",
              width: "100%",
              margin: "0 auto",
            }}
          >
            {activeStep === 0 && <AgentTypeStep />}
            {activeStep === 1 && <AgentStep />}
            {activeStep === 2 && <CallFlowStep />}
            {activeStep === 3 && <AgentSummaryStep />}
          </Stack>

          <Divider style={{ margin: "20px 0px" }} />

          <Box
            sx={{
              display: "flex",
              justifyContent: activeStep === 0 ? "flex-end" : "space-between",
              gap: "20px",
              marginTop: "20px",
            }}
          >
            {activeStep > 0 && (
              <Button
                leftIcon={<ArrowLeftOutline size={16} style={{ marginRight: "8px" }} />}
                variant="secondary"
                type="button"
                onClick={goToPreviousStep}
              >
                Previous
              </Button>
            )}
            {activeStep < 3 ? (
              <Button
                rightIcon={<ArrowRightOutline size={16} style={{ marginLeft: "8px" }} />}
                variant="primary"
                onClick={handleSubmit(onSubmit, (errors) => console.log(errors))}
              >
                Next
              </Button>
            ) : (
              <Button disabled={loading} variant="primary" type="submit" onClick={handleSubmit(onSubmit)}>
                {params.id ? "Update" : "Create"} Agent
                <ArrowRightOutline size={16} style={{ marginLeft: "8px" }} />
              </Button>
            )}
          </Box>
        </FormProvider>
      </Stack>
    </>
  );
}
