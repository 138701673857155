import Dropdown from "@/common/components/dropdown";
import FormLabel from "@/common/components/form-label/FormLabel";
import Input from "@/common/components/input";
import Switch from "@/common/components/switch";
import { getTeamUsers } from "@/redux/reducers/team/slices/listUsers";
import { useAppDispatch, useAppSelector } from "@/redux/store";
import {
  KnAiAgentBookingModeEnum,
  KnAiAgentCallFlowEnum,
  KnAiAgentCallToActionEnum,
  KnAiAgentRequestTransferEnum,
} from "@/services/generated";
import { AiVoiceCallFlowType } from "@/types/ai-voice";
import { Box, Typography, useTheme } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import React, { useCallback, useEffect, useMemo } from "react";
import { Controller, useFormContext, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import {
  AiAgentForm,
  reqKnowledgeBase,
  showAndReqBookingMode,
  showAndReqBookingWith,
  showAndReqCallToAction,
  showAndReqTransfer,
} from "..";
import { CALL_FLOW_TYPE_OPTIONS } from "../../constants";
import { getTeamAiVoiceSettings } from "@/redux/reducers/settings/team-ai-voice/get";
import config from "@/common/constants/config";
import { Loading } from "@kennected/components";
import { debounce } from "lodash";
import { processFaqUrl } from "@/redux/reducers/ai-voice/upsertAgent";

const callFlowOptions = Object.keys(CALL_FLOW_TYPE_OPTIONS)
  .map((value: AiVoiceCallFlowType) => ({
    value,
    label: CALL_FLOW_TYPE_OPTIONS[value].label,
  }))
  .filter((o) => {
    return (
      (o.value === KnAiAgentCallFlowEnum.Prompt && config.flagAiVoiceFlowPrompt) ||
      (o.value === KnAiAgentCallFlowEnum.AnswerFaqs && config.flagAiVoiceFlowFaq) ||
      (o.value === KnAiAgentCallFlowEnum.QualifyAndBookAMeeting && config.flagAiVoiceFlowQualifyAndBook) ||
      (o.value === KnAiAgentCallFlowEnum.QualifyAndLiveTransfer && config.flagAiVoiceFlowQualifyAndLiveTransfer) ||
      (o.value === KnAiAgentCallFlowEnum.BookAMeeting && config.flagAiVoiceFlowBook) ||
      (o.value === KnAiAgentCallFlowEnum.ConfirmAMeeting && config.flagAiVoiceFlowConfirm)
    );
  });

export const VALUE_ONE_TO_ONE = "ONE_TO_ONE";

const BOOKING_MODE_OPTIONS = [
  {
    label: "Round Robin",
    value: KnAiAgentBookingModeEnum.RoundRobin,
  },
  {
    label: "1:1",
    value: VALUE_ONE_TO_ONE,
  },
];

const CALL_TO_ACTION_OPTIONS = [
  {
    label: "Book a Meeting",
    value: KnAiAgentCallToActionEnum.BookMeeting,
  },
  {
    label: "Create Ticket",
    value: KnAiAgentCallToActionEnum.CreateTicket,
  },
  {
    label: "Live Transfer",
    value: KnAiAgentCallToActionEnum.LiveTransfer,
  },
];

type BookingWithOption = {
  label: string;
  value: string;
};

export default function CallFlowStep() {
  const { t } = useTranslation();
  const theme = useTheme();
  const watch = useWatch<AiAgentForm>();
  const { control, setValue } = useFormContext<AiAgentForm>();
  const dispatch = useAppDispatch();
  const { users: teamUsers, loading: teamUsersLoading } = useAppSelector(({ team }) => team.listUsers);
  const isLoadingFaq = useAppSelector(({ aiVoice }) => aiVoice.upsertAgent.isLoadingFaq);
  const transferPhoneNumberBilling = useAppSelector(
    ({ settings }) => settings.teamAiVoice.active.transferPhoneNumberBilling,
  );
  const transferPhoneNumberSales = useAppSelector(
    ({ settings }) => settings.teamAiVoice.active.transferPhoneNumberSales,
  );
  const transferPhoneNumberSupport = useAppSelector(
    ({ settings }) => settings.teamAiVoice.active.transferPhoneNumberSupport,
  );
  const callFlow = watch["call_flow"] || KnAiAgentCallFlowEnum.QualifyAndBookAMeeting;
  const callToAction = watch["call_to_action"];
  const bookingMode = watch["booking_mode"];

  useEffect(() => {
    dispatch(getTeamAiVoiceSettings());
  }, [dispatch]);

  const transferOptions = useMemo(() => {
    const options = [];
    if (transferPhoneNumberSales) {
      options.push({
        label: "Sales",
        value: KnAiAgentRequestTransferEnum.Sales,
      });
    }
    if (transferPhoneNumberBilling) {
      options.push({
        label: "Billing",
        value: KnAiAgentRequestTransferEnum.Billing,
      });
    }
    if (transferPhoneNumberSupport) {
      options.push({
        label: "Support",
        value: KnAiAgentRequestTransferEnum.Support,
      });
    }
    return options;
  }, [transferPhoneNumberBilling, transferPhoneNumberSupport, transferPhoneNumberSales]);

  useEffect(() => {
    if (teamUsers === undefined && !teamUsersLoading) {
      dispatch(getTeamUsers());
    }
  }, [dispatch, teamUsers, teamUsersLoading]);

  const bookingWithOptions: BookingWithOption[] = useMemo(() => {
    return (teamUsers || []).map((u) => {
      return {
        label: `${u.firstName} ${u.lastName}`,
        value: u.user_id!,
      };
    });
  }, [teamUsers]);

  const debouncedProcessFaqUrl = useMemo(
    () => debounce((url: string) => dispatch(processFaqUrl(url)), 1000),
    [dispatch],
  );

  const handleFaqUrl = useCallback(
    (e: any) => {
      // setValue("knowledge_base_url", faqUrl);
      // setValue("knowledge_base_url", u);
      // if valid
      const faqUrl = e.target.value;
      console.log(faqUrl);
      debouncedProcessFaqUrl(faqUrl);
    },
    [debouncedProcessFaqUrl],
  );

  return (
    <form>
      <Box
        sx={{
          display: "grid",
          gap: "32px",
        }}
      >
        <Controller
          name="call_flow"
          control={control}
          render={({ field, fieldState }) => (
            <FormControl>
              <FormLabel label={t("callFlow.callFlow")} aria-required />
              <Dropdown
                options={callFlowOptions}
                value={field.value}
                onChange={(o) => field.onChange(o.value)}
                error={fieldState.error?.message}
              />
              {field.value && (
                <Typography
                  variant="body2"
                  sx={{
                    mt: 2,
                    fontSize: "14px",
                    color: theme.palette.commonColors.text,
                    bgcolor: theme.palette.midnight[20],
                    overflow: "hidden",
                    p: 1,
                    borderRadius: 2,
                  }}
                >
                  {CALL_FLOW_TYPE_OPTIONS[field.value].description}
                </Typography>
              )}
            </FormControl>
          )}
        />

        {callFlow === KnAiAgentCallFlowEnum.Prompt && (
          <Controller
            name="call_flow_prompt"
            control={control}
            render={({ field, fieldState }) => (
              <FormControl>
                <FormLabel label={"Prompt"} aria-required />
                <Input {...field} error={fieldState.error?.message} multiline rows={6} />
              </FormControl>
            )}
          />
        )}

        <Controller
          name="hook_statement"
          control={control}
          render={({ field, fieldState }) => (
            <FormControl>
              <FormLabel label={t("callFlow.hookStatement")} />
              <Input {...field} placeholder={t("callFlow.hookStatement")} error={fieldState.error?.message} />
            </FormControl>
          )}
        />

        {showAndReqBookingMode(callFlow) && (
          <Controller
            name="booking_mode"
            control={control}
            render={({ field, fieldState }) => (
              <FormControl>
                <FormLabel label={"Booking Mode"} aria-required />
                <Dropdown
                  options={BOOKING_MODE_OPTIONS}
                  value={field.value}
                  onChange={(o) => field.onChange(o.value)}
                  error={fieldState.error?.message}
                />
              </FormControl>
            )}
          />
        )}

        {showAndReqBookingWith(callFlow, bookingMode) && (
          <Controller
            name="booking_with"
            control={control}
            render={({ field, fieldState }) => (
              <FormControl>
                <FormLabel label={"Booking With"} aria-required />
                <Dropdown
                  value={field.value as any} // TODO: Fix multi values
                  ref={field.ref}
                  isMulti
                  options={bookingWithOptions}
                  onChange={(options) => {
                    field.onChange(options.map((option: any) => option.value));
                  }}
                  error={fieldState.error?.message}
                />
              </FormControl>
            )}
          />
        )}

        <Box>
          <Controller
            name="knowledge_base_url"
            control={control}
            render={({ field, fieldState }) => (
              <FormControl>
                <FormLabel label="Knowledge Base URL" aria-required={reqKnowledgeBase(callFlow)} />
                <Input value={field.value} onChange={handleFaqUrl} error={fieldState.error?.message} />
              </FormControl>
            )}
          />
          {isLoadingFaq && <Loading />}
        </Box>

        {showAndReqCallToAction(callFlow) && (
          <Controller
            name="call_to_action"
            control={control}
            render={({ field, fieldState }) => (
              <FormControl>
                <FormLabel label={"Call to Action"} aria-required />
                <Dropdown
                  options={CALL_TO_ACTION_OPTIONS}
                  value={field.value}
                  onChange={(o) => field.onChange(o.value)}
                  error={fieldState.error?.message}
                />
              </FormControl>
            )}
          />
        )}

        {showAndReqTransfer(callFlow, callToAction) && (
          <Controller
            name="transfer"
            control={control}
            render={({ field, fieldState }) => (
              <FormControl>
                <FormLabel label={t("callFlow.transfer")} aria-required />
                <Dropdown
                  options={transferOptions}
                  value={field.value}
                  onChange={(o) => field.onChange(o.value)}
                  error={fieldState.error?.message}
                />
              </FormControl>
            )}
          />
        )}

        <Box sx={{ display: "flex", gap: 3 }}>
          <Controller
            name="catch_webhook"
            control={control}
            render={({ field, fieldState }) => (
              <FormControl sx={{ width: "100%" }}>
                <FormLabel label={t("callFlow.thirdPartyWebhook")} />
                <Input
                  placeholder="https://www.webhook-url-address.com"
                  value={field.value}
                  onChange={field.onChange}
                  error={fieldState.error?.message}
                />
              </FormControl>
            )}
          />

          <Controller
            name="double_call"
            control={control}
            render={({ field, fieldState }) => (
              <FormControl sx={{ width: "120px" }}>
                <FormLabel label={t("callFlow.doubleCall")} />
                <Switch
                  error={fieldState.error?.message}
                  value={!!field.value}
                  onChange={(value) => field.onChange(value)}
                />
              </FormControl>
            )}
          />
        </Box>
      </Box>
    </form>
  );
}
